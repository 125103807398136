.dashboard-fullsize-portrait-minimal {
  header {
    &.fixed-top {
      & > div
      {
        .logo {
          width: calc( 3.4 * 7vh);
          height: 7vh;
          &.right {
            margin-right: 5vw;
          }
        }
      }
    }
  }

  .background {
    video {
      position: absolute;
      right: 0;
      width: auto;
    }
  }

  .dashboard {
    margin: 8vh 4vw 2vh 4vw;
    width: calc( 100vw - 8vw );
    height: calc( 100vh - 13vh );
    grid-template-rows: 20vw 1fr;

    h1, h2 {
      font-size: 9vw;
    }

    .commission-list-wrapper {
      .commission-list-title {
        font-size: 9vw;
        margin-bottom: 2vw;
      }

      .commission-list {
        .commission-list-items {
          grid-template-rows: repeat(auto-fill, 9vw);
          gap: 4vw 6vw;
        }
        .commission-list-item {
          font-size: 9vw;
          max-height: 9vw;
          margin-bottom: 0;
        }

        &.readyForPickup {
          .commission-list-items {
            grid-template-rows: repeat(auto-fill, 12vw);
          }
          .commission-list-item {
            font-size: 14vw;
            max-height: 14vw;
          }
        }
      }
    }
  }
}