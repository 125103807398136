

.scm-navigation {
  display: flex;
  margin-bottom: 1rem;

  ul {
    align-self: center;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;
      margin: 0;
      cursor: pointer;
      border-radius: var(--bs-border-radius);

      &.active {
        color: var(--bs-white);
        background: var(--bs-primary);
      }

      a {
        color: inherit;
        padding: 1rem 2rem;

        &.placeholder {
          width: 100px;
          margin: 1rem 2rem;
          padding: 0;
        }
      }
    }
  }
  .logo {
    width: 320px;
    flex-shrink: 0;
    &.right {
      margin-left: auto;
    }
  }
}

@include media-breakpoint-down(lg) {
  .scm-navigation {
    flex-wrap: wrap;
    ul {
      order: 2;
      flex-wrap: wrap;
      li {
        a {
          width: 100%;
        }
      }
    }
    .logo {
      order: 1;
    }
  }
}

