
.notistack-MuiContent {
  &.notistack-MuiContent-info {
    background-color: var(--bs-primary);
  }
  &.notistack-MuiContent-success {
    background-color: var(--bs-success);
  }
  &.notistack-MuiContent-warning {
    background-color: var(--bs-warning);
  }
  &.notistack-MuiContent-error {
    background-color: var(--bs-danger);
  }
}