
.scm-footer {
  font-size: .875rem;
  display: flex;
  margin-top: .5rem;

  .manager-info {
    margin-left: auto;

    a:not(:only-child) {
      &:before {
        content: ' - ';
      }
    }
  }
}