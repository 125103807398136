

$commissionMaxWidth: 500px;

@import "commissionHeader";
@import "commissionStateList";
@import "commissionInfos";
@import "commissionLineItems";
@import "commissionNewsletter";
@import "commissionFreeCoffee";
@import "commissionReview";

.commission-details {
  margin-top: 230px;
  padding: 1px;

  > div {
    margin-top: 2rem
  }
}

@include media-breakpoint-up(md) {
  .commission-details {
    margin-top: 260px;
  }
}

@include media-breakpoint-up(xl) {
  .commission-details {
    margin-top: 280px;
  }
}