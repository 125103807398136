
.minimal {
  &.commission-list-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;


    .commission-list-title {
      font-size: 2rem;
    }

    .commission-list {
      font-family: CeraProBlack;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .commission-list-items {
        flex-grow: 1;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto-fill, 40px);
        align-items: center;
      }

      .commission-list-item {
        font-size: 2rem;
        max-height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }


      &.readyForPickup {
        .commission-list-items {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(auto-fill, 80px);
        }

        .commission-list-item {
          color: $colorPink;
          font-size: 3rem;
          max-height: 80px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .minimal {
    &.commission-list-wrapper {
      .commission-list-title {
        font-size: 3rem;
      }

      .commission-list {
        .commission-list-items {
          grid-gap: 20px 60px;
          grid-template-rows: repeat(auto-fill, 70px);
        }

        .commission-list-item {
          font-size: 3rem;
          max-height: 70px;
        }


        &.readyForPickup {
          .commission-list-items {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(auto-fill, 100px);
          }

          .commission-list-item {
            font-size: 5rem;
            max-height: 100px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .minimal {
    &.commission-list-wrapper {
      grid-template-rows: 1fr 1fr;
      gap: 20px;

      .commission-list-title {
        font-size: 5rem;
      }

      .commission-list {
        margin-top: 20px;

        .commission-list-items {
          grid-gap: 20px 60px;
          grid-template-rows: repeat(auto-fill, 90px);
        }

        .commission-list-item {
          font-size: 5rem;
          max-height: 90px;
        }


        &.readyForPickup {
          .commission-list-items {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(auto-fill, 150px);
          }

          .commission-list-item {
            font-size: 8.5rem;
            max-height: 150px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .minimal {
  }
}
@include media-breakpoint-up(xxl) {
  .minimal {

  }
}