
.detail {
  &.commission-list-wrapper {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 60%;
    .spinner-border {
      margin: auto;
    }

    .commission-list {
      overflow: hidden;
      padding: 40px 0;
      margin: 0;
    }

    li.commission-list-item {
      font-size: 2rem;
      list-style: none;
      line-height: 1.1;
      overflow: hidden;

      display: none;

      .prefix {
        display: none
      }

      &.show {
        display: list-item;
      }

      b {
        display: inline-block;
        font-family: CeraProBlack;
        width: 55px;
      }

      span {
        font-style: italic;
      }

      &.readyForPickup {
        font-size: 2.25rem;

        b {
          color: $colorPink;
          width: 60px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .detail {
    &.commission-list-wrapper {
      .commission-list {
        padding: 100px 0;
      }

      li.commission-list-item {
        font-size: 3rem;

        b {
          width: 80px;
        }

        &.readyForPickup {
          font-size: 3.75rem;

          b {
            width: 100px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .detail {
    &.commission-list-wrapper {
      grid-template-rows: 60% 40%;

      .commission-list {
        padding: 0;
      }
      li.commission-list-item {
        font-size: 3rem;

        b {
          width: 90px;
        }

        &.readyForPickup {
          font-size: 5rem;

          b {
            width: 140px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .detail {
    &.commission-list-wrapper {
      li.commission-list-item {
        font-size: 4.5rem;

        b {
          width: 140px;
        }

        &.readyForPickup {
          font-size: 6rem;

          b {
            width: 175px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .detail {
    &.commission-list-wrapper {
      li.commission-list-item {
        font-size: 5.5rem;

        b {
          width: 180px;
        }

        &.readyForPickup {
          font-size: 8.5rem;

          b {
            width: 270px;
          }
        }
      }
    }
  }
}