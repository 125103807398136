
.commission-state {
  color: $fontGrey;
  text-transform: uppercase;

  .commission-badge {
    max-width: $commissionMaxWidth;
    border: 1px solid $grey;
    border-radius: 1rem;
    box-shadow: $boxShadow;
    background-color: $lightGrey;
    display: grid;
    height: 80px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr .5rem 1fr;

    grid-template-areas:
    "commissionNumber spacer commissionCreateDate"
    "commissionNumber spacer commissionCreateTime";

    .commission-number {
      grid-area: commissionNumber;

      display: flex;
      justify-content: right;
      align-items: center;

      font-family: CeraProBlack;
      font-weight: bold;
      font-size: 4rem;
      color: $bwtBlue;
    }

    .commission-createdate {
      grid-area: commissionCreateDate;
      align-items: flex-end;
      font-weight: bold;
    }

    .commission-createtime {
      grid-area: commissionCreateTime;
      align-items: flex-start;
    }

    .commission-createdate,
    .commission-createtime {
      display: flex;
      justify-content: left;

      line-height: 1;
    }
  }

  .commission-state-text {
    font-size: 1.5rem;
    margin: 1rem 0;
    .state {
      font-family: CeraProBlack;
      &.readyForPickup,
      &.completed {
        color: $colorGreen;
      }
      &.cancelled {
        color: $colorRed;
      }
    }
  }
}