.input-group {
  .barcode-text {
    flex-grow: 1;
    text-align: center;
    border: 1px solid var(--bs-gray-400);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}