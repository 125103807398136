.dashboard-fullsize-portrait {
  header {
    &.fixed-top {
      & > div
      {
        .logo {
          &.right {
            margin-right: 60px;
          }
        }
      }
    }
  }


  .dashboard {
    margin: 160px 60px 40px 60px;
    width: calc( 100vw - 120px );
    height: calc( 100vh - 200px );
    grid-template-rows: 185px 1fr;

    h1, h2 {
      font-size: 4rem;
    }

    .commission-list-wrapper {
      li.commission-list-item {
        margin-bottom: 2rem;

        .prefix {
          display: inline-block;
        }

        .separator {
          opacity: 0;
          &::after{
            content: "\a";
            white-space: pre;
          }
        }
        .status {
          padding-left: 60px;
        }
      }
    }
  }
}