@font-face {
  font-family: 'CeraPro';
  font-display: swap;
  src: url('../../assets/fonts/CeraPRO-Regular.woff2') format('woff2'), url('../../assets/fonts/CeraPRO-Regular.woff') format('woff'), url('../../assets/fonts/CeraPRO-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CeraPro';
  font-weight: bold;
  font-display: swap;
  src: url('../../assets/fonts/CeraPRO-Bold.woff2') format('woff2'), url('../../assets/fonts/CeraPRO-Bold.woff') format('woff'), url('../../assets/fonts/CeraPRO-Bold.ttf') format('truetype');
}


@font-face {
  font-family: 'CeraProBlack';
  font-display: swap;
  src: url('../../assets/fonts/CeraPro-Black.woff2') format('woff2'), url('../../assets/fonts/CeraPro-Black.woff') format('woff'), url('../../assets/fonts/CeraPro-Black.otf') format('opentype');
}

@font-face {
  font-family: 'CeraProBlack';
  font-style: italic;
  font-display: swap;
  src: url('../../assets/fonts/CeraPro-BlackItalic.woff2') format('woff2'), url('../../assets/fonts/CeraPro-BlackItalic.woff') format('woff'), url('../../assets/fonts/CeraPro-BlackItalic.otf') format('opentype');
}


@font-face {
  font-family: 'NexaSlab';
  font-display: swap;
  src: url('../../assets/fonts/NexaSlab-Regular.woff2') format('woff2'), url('../../assets/fonts/NexaSlab-Regular.woff') format('woff'), url('../../assets/fonts/NexaSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NexaSlabBold';
  font-display: swap;
  src: url('../../assets/fonts/NexaSlab-Bold.woff2') format('woff2'), url('../../assets/fonts/NexaSlab-Bold.woff') format('woff'), url('../../assets/fonts/NexaSlab-Bold.ttf') format('truetype');
}

$fontFamilyCeraPro: 'CeraPro', 'Inter', sans-serif;
$fontFamilyNexaSlab: 'NexaSlab', 'Inter', sans-serif;
$fontFamilyNexaSlabBold: 'NexaSlabBold', 'Inter', sans-serif;

