

.commission-line-items {
  --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);

  --bs-accordion-border-radius: 1rem;
  --bs-accordion-inner-border-radius: 1rem;

  --bs-accordion-btn-icon-width: 1.5rem;

  --bs-accordion-bg: #{$lightGrey};
  --bs-accordion-active-bg: #{$lightGrey};

  --bs-accordion-btn-focus-border-color: #{$grey};

  --bs-accordion-btn-color: #{$fontGrey};
  --bs-accordion-active-color: #{$fontGrey};
  --bs-accordion-color: #{$fontGrey};
  .accordion-button {
    font-size: .875rem;
    text-transform: uppercase;
  }

  .commission-line-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    .commission-line-item-image {
      width: 50px;
      height: 50px;
      min-width: 50px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 0.5rem;

      > img {
        max-width: 100%;
      }
    }

    .commission-line-item-label {
      font-size: .875rem
    }
  }
}