
.redeemStatus {
  align-items: center;

  &.success {
    color: var(--bs-success);
  }

  &.alreadyRedeemed {
    color: var(--bs-warning);
  }

  &.failed {
    color: var(--bs-danger);
  }

  .icon {
    font-size: 15rem;
  }

  .status {
    font-size: 1.5rem;
    text-align: center;
  }
}