
.flashbag {
  font-size: 1rem;
  margin-bottom: 1rem;
  > p:last-child {
    margin-bottom: 0;
  }

  border-radius: 1rem;
  padding: 1rem;

  color: #{$fontGrey};
  background: #{$lightGrey};
  border: 1px solid var(--bs-border-color);

  &.bold {
    font-weight: bold;
  }

  &.text-align-left {
    text-align: left;
  }
  &.text-align-center {
    text-align: center;
  }
  &.text-align-right {
    text-align: right;
  }

  &.success {
    border-color: var(--bs-success);
    color: var(--bs-success);
    background: rgba(114, 199, 76, 0.3);
  }

  &.warning {
    border-color: var(--bs-warning);
    color: var(--bs-warning);
    background: rgba(255, 217, 134, 0.49);
  }

  &.error {
    border-color: var(--bs-danger);
    color: var(--bs-danger);
    background: rgba(255, 112, 126, 0.47);
  }
}