@import "notistack.overrides";

.scm-administration {
  height: 100vh;
  width: 100vw;
  padding: .5rem 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  color: $fontGrey;

  &.background {
    background: $lightGrey;
  }

  .spinner-border {
    border-color: $bwtBlue;
    border-right-color: transparent;
  }

  .navigate-back {
    text-decoration: none;
    color: $fontGrey;
    font-size: .875rem;
    margin-bottom: .5rem;
  }


  @import "login";
  @import "navigation";
  @import "content";
  @import "table";
  @import "edit";
  @import "dynamicList";
  @import "barcodeModal";
  @import "footer";
}



