
$logoProportion: 280.5/83;
$logoHeight: 120px;

header {
  > div {
    display: flex;
  }

  .logo {
    background: #FFF;
    height: $logoHeight;
    width: calc( $logoHeight * $logoProportion);


    margin: 0 20px 0 0;
    &.right {
      margin: 0 0 0 auto;
    }
  }

  &.fixed-top {
    &.scrolled {
      border-bottom: 1px solid $grey;
      box-shadow: $boxShadow;
    }


    > div {
      .logo {
        margin: 0 40px;

        &.right {
          margin-left: auto;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  header {
    .logo {
      $logoHeight: 100px;
      height: $logoHeight;
      width: calc( $logoHeight * $logoProportion);
    }
  }
}

@include media-breakpoint-down(sm) {
  header {
    > .container {
      padding: 0;
    }

    .logo {
      $logoHeight: 75px;
      height: $logoHeight;
      width: calc( $logoHeight * $logoProportion);
    }
    &.fixed-top {
      > div {
        .logo {
          margin: 0;
        }
      }
    }
  }
}
