
.commission-coffee {
  font-size: 1rem;

  h6 {
    font-family: CeraProBlack;
    text-transform: uppercase;
  }

  .btn-primary {
    border-color: $bwtBlue;
    color: $bwtWhite;
    margin: 0;
    width: 100%;
  }

  form {
    max-width: $commissionMaxWidth;

    .form-control {
      border-radius: 0;
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  .commission-newsletter-qrcode {
    max-width: $commissionMaxWidth;
    text-align: center;
    margin: 2rem 0 1rem 0;
  }
}
