
.dynamic-list {
  .form-control {
    border: none;
    background: transparent;
    padding: 0;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .card-header {
    padding: 1rem;
  }

  &.collapsed {
    .card-header {
      border: none;
    }
    .accordion-collapse {
      display: none;
    }
  }

  .accordion-collapse {
    overflow: hidden;
    border-radius: var(--bs-border-radius);
    padding: 0;

    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0;
        padding: .75rem 1rem;
        display: flex;
        
        > svg {
          cursor: pointer;
          margin-left: -6px;
        }

        &:nth-child(even) {
          background: var(--bs-light);
        }
      }
    }

    > p {
      margin: 0;
      padding: .75rem 1rem;
    }
  }
}