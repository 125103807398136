

.scm-login {
  align-self: center;
  margin: auto;
  width: 100%;
  max-width: 500px;

  border-radius: 1rem;
  padding: 1rem;

  color: #{$fontGrey};
  background: #{$lightGrey};
  border: 1px solid var(--bs-border-color);

  button {
    width: 100%;
    border-radius: var(--bs-border-radius);
  }
}