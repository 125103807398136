

.commission-details {
  > .commission-state-list {
    margin-top: 0;
  }
}

.commission-state-list {
  display: flex;
  flex-direction: column;

  .commission-state-list-state {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 80px .5rem 1fr;

    grid-template-areas:
    "badge spacer label"
    "badge spacer date";

    font-size: 1.125rem;
    line-height: 1;
    text-transform: uppercase;
    padding: 1.5rem 0;
    overflow: hidden;

    .commission-state-list-state-badge {
      grid-area: badge;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -1.9rem;
        height: 2rem;
        width: .25rem;
        background-color: $fontGrey;
        left: calc(50% - (0.25rem/2));
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -1.9rem;
        height: 2rem;
        width: .25rem;
        background-color: $fontGrey;
        left: calc(50% - (0.25rem/2));
      }
    }

    .commission-state-list-state-label {
      grid-area: label;
      font-family: CeraProBlack;
      display: flex;
      align-items: flex-end;
    }

    .commission-state-list-state-date {
      grid-area: date;
      display: flex;
      align-items: flex-start;
    }

    &.created {
      order: 10;
    }
    &.inProgress {
      order: 20;
    }
    &.readyForPickup {
      order: 30;
    }
    &.paid {
      order: 40;
    }
    &.handover {
      order: 50;
    }
    &.completed {
      order: 60;
    }
    &.cancelled {
      order: 1000;
    }
  }

  &.prepaid {
    .commission-state-list-state {
      &.paid {
        order: 15;
      }
    }
  }

  .commission-state-list-state {
    &.done {
      .commission-state-list-state-badge {
        color: $colorGreen;
        &:before,
        &:after {
          background: $colorGreen;
        }
      }
    }
  }

  &.cancelled {
    .commission-state-list-state {
      .commission-state-list-state-badge {
        color: $colorRed;
        &:before,
        &:after {
          background: $colorRed;
        }
      }
    }
  }

  .commission-state-list-state {
    &:first-child {
      padding-top: 0;
      .commission-state-list-state-badge {
        &:before {
          display: none;
        }
      }
    }
    &:last-child {
      padding-bottom: 0;
      .commission-state-list-state-badge {
        &:after {
          display: none;
        }
      }
    }
  }

}