

.spinner-border-xxl {
  width: 15rem;
  height: auto;
  aspect-ratio: 1 / 1;
  border-width: 0.5rem;
  animation-duration: 2s;
  border-color: $bwtBlue;
  border-right-color: transparent;
  margin: 2rem calc((100% - 15rem) / 2 ) !important
}