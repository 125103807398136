
body {
  font-family: $fontFamilyCeraPro, var(--bs-body-font-family);
  color: $bwtWhite;
}

a {
  cursor: pointer;
  color: $bwtWhite;
  &:hover{
    color: $bwtPink;
  }
}

div#root {
  > .background + .container,
  > header + .container{
    margin-top: 1rem;
  }
  > .container:last-of-type {
    margin-bottom: 1rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  margin-top: 1rem;
  border-radius: 0;
  padding: 1rem;
  box-shadow: $boxShadow;
  border-color: $bwtWhite;
  &:hover:not([class*="btn-"]){
    background-color: $bwtPink;
    border-color: $bwtWhite;
  }
}

.qr-reader {
  &:not(:empty) {
    margin-bottom: 1rem;
  }
}

.dark-font {
  color: $fontGrey;

  a {
    color: $bwtBlue;
  }
}

.badge.clickable {
  cursor: pointer;
}

svg {
  &.primary {
    color: var(--bs-primary);
  }
  &.secondary {
    color: var(--bs-secondary);
  }
  &.success {
    color: var(--bs-success);
  }
  &.warning {
    color: var(--bs-warning);
  }
  &.danger {
    color: var(--bs-danger);
  }
}

@include media-breakpoint-only(xs) {
  h1, h2 {
    margin-bottom: 1rem;
  }
}