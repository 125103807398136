

.commission-review {
  font-size: 1rem;

  h6 {
    font-family: CeraProBlack;
    text-transform: uppercase;
  }

  .btn-outline-primary {
    border-color: $bwtBlue;
    margin: 0;
    width: 100%;
  }

  .btn-primary {
    border-color: $bwtBlue;
    color: $bwtWhite;
    margin: 0;
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .commission-review {
    .btn-outline-primary {
      max-width: $commissionMaxWidth;
    }
  }
}