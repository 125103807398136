
.scm-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;


  .manager-card {
    border-radius: 1rem;
    border: 1px solid var(--bs-border-color);
    box-shadow: 0 1px 1px rgba(0,0,0,.08), 0 2px 1px rgba(0,0,0,.06), 0 1px 3px rgba(0,0,0,.1);
    background: var(--bs-white);

    margin-bottom: 1rem;
    min-height: 200px;

    @include media-breakpoint-only(xs) {
      min-height: unset;
    }

    &:last-child {
      margin-bottom: 0;
    }
    &.expanding-height {
      height: 100%;
    }

    .manager-card-title {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
      border-bottom: 1px solid var(--bs-border-color);
    }

    .manager-card-content {
      padding: 1rem;
    }

    .flashbag{
      border-radius: 0;
    }
  }
}