.manager-table-container {
  @include media-breakpoint-only(xs) {
    overflow-x: scroll;
  }

  .manager-table {
    .manager-table-actions {
      width: 0;
      white-space: nowrap;
    }
    .manager-table-action {
      color: $fontGrey;
      margin-left: .75rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
