
.dashboard {
  margin: 100px 10px 10px 10px;
  width: calc( 100vw - 20px );
  height: calc( 100vh - 110px );
  grid-template-rows: 65px 1fr;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  color: $bwtWhite;
  text-transform: uppercase;

  h1 {
    font-family: CeraProBlack;
    font-weight: bold;
    font-style: italic;
  }

  h1, h2 {
    font-size: 2rem;
    line-height: 1;
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .dashboard {
    margin: 120px 100px 20px 100px;
    width: calc( 100vw - 200px );
    height: calc( 100vh - 140px );
    grid-template-rows: 125px 1fr;

    h1, h2 {
      font-size: 3rem;
    }
  }
}
@include media-breakpoint-up(lg) {
  .dashboard {
    h1, h2 {
      font-size: 3rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .dashboard {
    margin: 120px 120px 40px 120px;
    width: calc( 100vw - 240px );
    height: calc( 100vh - 160px );
    grid-template-rows: 145px 1fr;

    h1, h2 {
      font-size: 3.5rem;
    }
  }
}
@include media-breakpoint-up(xxl) {
  .dashboard {
    grid-template-rows: 145px 1fr;

    h1, h2 {
      font-size: 4rem;
    }
  }
}